import { graphql } from 'gatsby'
import React from 'react'
import LiveStreamBase from '../components/live-stream-base'
import { TWITCH_ACCOUNT_TEST } from '../config'

class LiveStreamTest extends React.Component {
  render() {
    return <LiveStreamBase data={this.props.data} twitchAccount={TWITCH_ACCOUNT_TEST} vid="https://stream.hardwarehookups.com.au/test/hls/test.m3u8"></LiveStreamBase>
  }
}

export default LiveStreamTest

export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              link
              stream
              location
              startTime
              endTime
              bannerImage
            }
            fields {
              date
            }
          }
        }
      }
    }
  `
